import { graphql } from 'gatsby';
export default from './kontakt';

export const kontaktQuery = graphql `
  query kontaktENQuery {
    contentfulKontaktSeite(node_locale: {eq: "en"}) {
      ...KontaktQueryFragment
    }
  }
`
